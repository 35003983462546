/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SearchFilter() {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  function handleSearch(e) {
    e.preventDefault();
    if (!search) {
      return navigate("");
    }
    navigate(`?search=${search.replace(/\s/g, "").toLowerCase()}`);
  }

  function handleOnChange(e) {
    setSearch(e.target.value);
    setTimeout(() => {
      if (e.target.value === "") {
        return navigate("");
      }
      navigate(`?search=${e.target.value.replace(/\s/g, "").toLowerCase()}`);
    }, 500);
  }

  return (
    <form onSubmit={handleSearch}>
      <input
        type="search"
        placeholder="Search"
        value={search}
        onChange={handleOnChange}
        className="bg-brand-bg border border-neutral-300 text-brand-text text-sm rounded-lg block w-full p-2.5 dark:border-neutral-300 dark:placeholder-neutral-400"
      />
    </form>
  );
}
