import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import authServices from '../../services/auth.service';
import Input from './../BankInfo/Input';

const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

const passwordValidation = Yup.object({
  newPassword: Yup.string()
    .required(
      'Enter a combination of at least six numbers,letters and punctuation marks(such as ! and &).'
    )
    .min(6, 'Password must be atleast 6 characters.')
    .max(36, "Password can't be more than 36 characters"),
  confirmPassword: Yup.string()
    .required('Confirm your password.')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match.'),
});

export default function ResetPasswordForm({ token }) {
  const [inputState, setInputState] = useState(initialValues);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const response = await authServices.resetPassword(
        values.newPassword,
        token
      );
      if (response.success) {
        toast.success(response.status);

        navigate('/', { replace: true });
      } else {
        toast.error(response.error.message);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error || status);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputState({ ...inputState, [name]: value });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={inputState}
      validationSchema={passwordValidation}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <Form>
          <Input
            type="password"
            name="newPassword"
            placeholder="Enter new password"
            onBlur={form.handleBlur}
            value={inputState.email}
            onChange={handleChange}
            required
          />
          <Input
            type="password"
            name="confirmPassword"
            placeholder="Confirm new password"
            onBlur={form.handleBlur}
            value={inputState.email}
            onChange={handleChange}
            required
          />

          <div className="w-full flex justify-end">
            <button
              disabled={form.isSubmitting}
              type="submit"
              className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
            >
              Reset my Password
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
