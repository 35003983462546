import { createSlice } from '@reduxjs/toolkit';

const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: {
    withdrawData: null,
    withdrawalHistory: [],
    withdrawalStatus: null,
    allTransection: [],
    userTotalWithdraw: null,
  },
  reducers: {
    setWithdrawalData: (state, action) => {
      state.withdrawData = action.payload;
    },
    setWithdrawalHistory: (state, action) => {
      state.withdrawalHistory = action.payload;
    },
    setWithdrawalStatus: (state, action) => {
      state.withdrawalStatus = action.payload;
    },

    setAllTransectons: (state, action) => {
      state.allTransection = action.payload;
    },

    setUserWithdrawalTotals: (state, action) => {
      state.userTotalWithdraw = action.payload;
    },
  },
});

export const {
  setWithdrawalData,
  setWithdrawalHistory,
  setWithdrawalStatus,
  setAllTransectons,
  setUserWithdrawalTotals,
} = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
