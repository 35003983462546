import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import AdminDashboardWrapper from "../../components/SuperAdmin/AdminDashboardWrapper";
import DashboardTable from "../../components/dashboard/Table";
import CustomReferral from "../../components/SuperAdmin/CustomReferral";

const columns = [
  {
    id: "reference",
    label: "Reference",
    minWidth: 100,
    format: (value) => "#" + value,
  },
  {
    id: "platform",
    align: "center",
    label: "Platform",
    minWidth: 350,
  },
  {
    id: "email",
    align: "center",
    label: "Email",
    minWidth: 250,
  },
  // {
  //   id: "points",
  //   label: "Points",
  //   minWidth: 40,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "earned",
    align: "center",
    label: "Earned",
    minWidth: 100,
    format: (value) => "$" + value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "date",
    label: "Date",
    minWidth: 190,
    align: "center",
  },
];

function createData(
  reference,
  platform,
  email,

  earned,
  description,
  date,
  pending
) {
  return { reference, platform, email, earned, description, date, pending };
}

function Referrals() {
  const activities = useSelector((state) => state.admin.referActivities);
  const [rows, setRows] = useState([]);

  const [openCustomReferral, setOpenCustomReferral] = useState(false);

  useEffect(() => {
    if (activities) {
      let mapData = activities.map((dt) => {
        return createData(
          dt.reference,
          dt.platform,
          dt.email,
          dt.earned,
          dt.description,
          moment(dt.createdAt).format("llll"),
          dt.pending
        );
      });

      setRows(mapData);
    }
  }, [activities]);

  return (
    <AdminDashboardWrapper>
      {" "}
      <div className="mt-12">
        {" "}
        <div className="flex flex-wrap justify-between">
          <h2 className="text-lg font-medium">Recent referral activities</h2>
          <button
            onClick={() => setOpenCustomReferral(true)}
            className="rounded-md bg-brand-primary text-white px-8 py-2"
          >
            Add entry
          </button>
        </div>
        <DashboardTable
          className="mt-6 bg-brand-card"
          columns={columns}
          rows={rows}
        />
      </div>{" "}
      <CustomReferral
        open={openCustomReferral}
        handleClose={() => setOpenCustomReferral(false)}
      />
    </AdminDashboardWrapper>
  );
}

export default Referrals;
