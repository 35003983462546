import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from "./utils/functions";
import { useDispatch, useSelector } from "react-redux";
import authServices from "./services/auth.service";

import useSocket from "./context/Socket";

import { updateDashboardData } from "./utils/reducers/dashboardSlice";

// pages
import LogIn from "./pages/auth/LogIn";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/Reset";
import Settings from "./pages/auth/Settings";
import AdminSettings from "./pages/auth/AdminSettings";

import Dashboard from "./pages/dashboard/index";
import AffiliateUrls from "./pages/dashboard/AffiliateUrls";
import Referrals from "./pages/dashboard/Referrals";
import Payouts from "./pages/dashboard/Payouts";
import Teams from "./pages/dashboard/Teams";
import Statistics from "./pages/dashboard/Statistics";

import PrivateRoutes from "./routes/PrivateRoute";
import PublicRoutes from "./routes/PublicRoute";
import PrivetRoutesAdmin from "./routes/PrivateRouteAdmn";
import AdminDashboard from "./pages/superAdmin/AdminDashboard";
import Users from "./pages/superAdmin/Users";
import TransectionHistory from "./pages/dashboard/TransectionHistory";
import Notification from "./pages/superAdmin/Notification";
import NotificationViewer from "./components/SuperAdmin/NotificationViewer";
import AdminTransectionList from "./pages/superAdmin/AdminTransectionList";
import AdminReferrals from "./pages/superAdmin/Referrals";

function App() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatch(authServices.getUser());
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
      }
    })();
  }, []);

  useEffect(() => {
    let theme = getFromLocalStorage("theme");

    if (theme && theme === "light") {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    } else {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    socket.emit("identity", user._id);

    socket.on("new_activity", (data) => {
      console.log("new_activity");
      console.log(data);
      dispatch(updateDashboardData(data));
    });
    socket.on("new_visit", (data) => {
      console.log("new_visit");
      console.log(data);
      dispatch(updateDashboardData(data));
    });
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<LogIn />} />
          <Route path="register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
        </Route>

        <Route path="/" element={<PrivateRoutes />}>
          <Route path="dashboard">
            <Route path="" element={<Dashboard />} />
            <Route path="affiliate_urls" element={<AffiliateUrls />} />
            <Route path="referrals" element={<Referrals />} />
            <Route path="payouts" element={<Payouts />} />
            <Route
              path="transection-history"
              element={<TransectionHistory />}
            />
            <Route path="teams" element={<Teams />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>

        {/* Super Admin Panel */}
        <Route path="/" element={<PrivetRoutesAdmin />}>
          <Route path="admin/dashboard">
            <Route path="" element={<AdminDashboard />} />

            <Route path="users" element={<Users />} />
            <Route path="notification" element={<Notification />}>
              <Route path=":notificationId" element={<NotificationViewer />} />
            </Route>

            <Route path="referrals" element={<AdminReferrals />} />
            <Route path="transections" element={<AdminTransectionList />} />
            <Route path="settings" element={<AdminSettings />} />

            {/* <Route path="notification" element={<Notification />}>
                <Route
                  path=":notificationId"
                  element={<NotificationViewer />}
                />
              </Route>
              <Route path="manage_access" element={<ManageAccess />} />
              <Route path="settings" element={<AdminSettings />} /> */}
          </Route>
        </Route>
        {/* Super Admin Panel */}
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
