import React, { useEffect, useState } from "react";
import { Form, Formik, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import adminServices from "../../services/admin.service";

import { Dialog } from "@mui/material";

const initialValues = {
  fullName: "",
  email: "",
  platform: "---select---",
  referCode: "---select---",
  description: "",
  earned: "",
};

const formValidation = Yup.object({
  fullName: Yup.string()
    .required("Please tell us your first name!")
    .min(6, "Full name must be between 6 and 64 characters.")
    .max(64, "Full name must be between 6 and 64 characters.")
    .matches(/^[aA-zZ]/, "Number and special characters are not allowed."),
  email: Yup.string()
    .required("Email address is required.")
    .email("Please enter a valid email address.")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Please enter a valid email address."
    ),
  platform: Yup.string()
    .required("Platform is required")
    .matches(/^(?!---select---$).*/i, "Platform is required"),
  referCode: Yup.string()
    .required("Refercode is required")
    .matches(/^(?!---select---$).*/i, "User is required"),
  description: Yup.string().required("Description is required"),
  earned: Yup.number().required("Earned amount is required"),
});

const platforms = [
  {
    name: "Review Site",
    value: "http://get-reviews.savagesales.com",
  },
];

function CustomReferral({ open, handleClose }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const allUsers = useSelector((state) => state.admin.users._list);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (values) => {
    let toastId = toast.loading("Processing...");
    let data = values;

    try {
      const response = await dispatch(
        adminServices.customEntryOrReferral(user.token, data)
      );

      if (response.success) {
        toast.update(toastId, {
          render: "Entry added!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        handleClose();
      } else {
        toast.update(toastId, {
          render: response.error.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;

      toast.update(toastId, {
        render: error.message || status,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
      <div className="px-8 md:px-12 py-10 text-brand-text bg-brand-card">
        <h2 className="mb-12 text-center font-bold md:text-2xl text-xl">
          {" "}
          Add your custom entry{" "}
        </h2>
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={formValidation}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <Form>
              <Input
                type="text"
                name="fullName"
                placeholder="Full Name"
                onBlur={form.handleBlur}
                value={formValues.fullName}
                onChange={handleChange}
              />
              <Input
                type="email"
                name="email"
                placeholder="E-mail"
                onBlur={form.handleBlur}
                value={formValues.email}
                onChange={handleChange}
              />
              <Selection
                placeholder="Select Platform"
                name="platform"
                onBlur={form.handleBlur}
                value={formValues.platform}
                onChange={handleChange}
                options={platforms}
              />
              <Selection
                placeholder="Select User"
                name="referCode"
                onBlur={form.handleBlur}
                value={formValues.referCode}
                onChange={handleChange}
                options={allUsers.map((dt) => ({
                  name: dt.email,
                  value: dt.referral,
                }))}
              />

              <Input
                type="number"
                name="earned"
                placeholder="How much credit will be added?"
                onBlur={form.handleBlur}
                value={formValues.earned}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="description"
                placeholder="Description"
                onBlur={form.handleBlur}
                value={formValues.description}
                onChange={handleChange}
              />

              <button
                type="submit"
                disabled={form.isSubmitting}
                className="rounded-md bg-brand-primary px-8 py-2 text-white"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}

function Selection({ placeholder, options, value, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div className="mb-5">
      <select
        {...field}
        {...props}
        name={field.name}
        className="bg-brand-bg text-brand-text leading-relaxed border-none outline-none w-full px-3 py-2 text-base rounded-md"
      >
        <option value="---select---"> {placeholder} </option>
        {options.map((dt, idx) => (
          <option key={idx} value={dt.value}>
            {" "}
            {dt.name}{" "}
          </option>
        ))}
      </select>
      {console.log(meta.touched, meta.error, value)}
      {meta.touched && meta.error && (
        <div className="text-sm text-red-600">
          <ErrorMessage name={field.name} />
        </div>
      )}
    </div>
  );
}

function Input({ placeholder, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div className="mb-5">
      <input
        type={field.type}
        name={field.name}
        placeholder={placeholder}
        {...field}
        {...props}
        className="bg-brand-bg text-brand-text leading-relaxed border-none outline-none w-full px-3 py-2 text-base rounded-md"
      />
      {meta.touched && meta.error && (
        <div className="text-sm text-red-600">
          <ErrorMessage name={field.name} />
        </div>
      )}
    </div>
  );
}

export default CustomReferral;
