import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import moment from "moment";

// components
import DashboardWrapper from "../../components/dashboard/Wrapper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Referrals Stats",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Statistics() {
  const dashboardData = useSelector((state) => state.dashboard);

  const data = useMemo(() => {
    let referralsData = new Array(12).fill(0);
    let visitsData = new Array(12).fill(0);

    dashboardData.activities.map((dt) => {
      let month = moment(dt.createdAt).month();
      referralsData[month]++;
    });
    dashboardData.visits.map((dt) => {
      let month = moment(dt.createdAt).month();
      visitsData[month]++;
    });

    return {
      labels,
      datasets: [
        {
          label: "Referrals",
          data: referralsData,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Visits",
          data: visitsData,
          borderColor: "rgb(205, 92, 92)",
          backgroundColor: "rgb(205, 92, 92, 0.5)",
        },
      ],
    };
  }, [dashboardData]);

  return (
    <DashboardWrapper>
      <div className="mt-12">
        <h1 className="text-lg text-center font-medium">Referrals Stats</h1>
        <div className="mt-12 max-w-3xl mx-auto">
          <Line options={options} data={data} />
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Statistics;
