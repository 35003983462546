import React, { useState } from "react";

import { toast } from "react-toastify";
import { Dialog } from "@mui/material";

// services
import authServices from "../../services/auth.service";
import { useDispatch } from "react-redux";

function Register({ cb, superAdminAction = false }) {
  const dispatch = useDispatch();

  const [isRegistering, setIsRegistering] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormErrors({ password: null, confirmPassword: null });
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setIsRegistering(false);
    cb();
  };

  const validateFields = (values) => {
    let validated = false;

    if (values.password !== values.confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Password didn't match with confirm password!",
        confirmPassword: "Confirm Password didn't match with password!",
      }));
      validated = false;
    } else {
      setFormErrors((prev) => ({
        ...prev,
        password: null,
        confirmPassword: null,
      }));
      validated = true;
    }

    return validated;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const isValidated = validateFields(formValues);
    let toastId = toast.loading("Processing...");

    if (isValidated) {
      setIsRegistering(true);
      try {
        let data = { ...formValues, accountApproval: superAdminAction };

        const response = await dispatch(
          authServices.register(data, superAdminAction)
        );
        console.log("re", response);
        if (response.success) {
          toast.update(toastId, {
            render: response.status,
            isLoading: false,
            autoClose: 3000,
            type: "success",
          });
          handleReset();
        } else {
          setIsRegistering(false);
          toast.update(toastId, {
            render: response.status,
            isLoading: false,
            autoClose: 3000,
            type: "error",
          });
        }
      } catch (err) {
        setIsRegistering(false);
        console.log(err);
        let error = err.response.data?.error;
        let status = err.response.data.status;

        toast.update(toastId, {
          render: error.message || status,
          isLoading: false,
          autoClose: 3000,
          type: "error",
        });
      }
    }
  };

  return (
    <div className="w-full sm:rounded-md shadow-lg break-words bg-brand-card">
      <div className="header font-bold text-brand-text py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
        Register
      </div>
      {/*  */}

      <div className="form pt-4">
        <form
          className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
          onSubmit={handleRegister}
        >
          <div>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formValues.firstName}
              required
              className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="lastName"
              value={formValues.lastName}
              placeholder="Last Name"
              required
              className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
              onChange={handleChange}
            />
          </div>

          <div className="email">
            {/* <label htmlFor="email">E-Mail Address</label> */}
            <input
              type="email"
              name="email"
              value={formValues.email}
              placeholder="E-Mail Address"
              required
              className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
              onChange={handleChange}
              autoComplete="off"
            />
          </div>

          <div className="flex flex-col space-y-1">
            {/* <label htmlFor="email">E-Mail Address</label> */}
            <input
              type="password"
              name="password"
              value={formValues.password}
              placeholder="Password"
              required
              className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
              onChange={handleChange}
              autoComplete="off"
            />
            {formErrors.password && (
              <span>
                {" "}
                <small className="text-red-600">
                  {" "}
                  {formErrors.password}{" "}
                </small>{" "}
              </span>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            {/* <label htmlFor="email">E-Mail Address</label> */}
            <input
              type="password"
              name="confirmPassword"
              value={formValues.confirmPassword}
              placeholder="Confirm Password"
              required
              className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
              onChange={handleChange}
              autoComplete="off"
            />

            {formErrors.confirmPassword && (
              <span>
                {" "}
                <small className="text-red-600">
                  {" "}
                  {formErrors.confirmPassword}{" "}
                </small>{" "}
              </span>
            )}
          </div>

          {/* buttton others */}
          <div className="flex flex-wrap">
            <button
              type="submit"
              className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-brand-primary sm:py-4"
              disabled={isRegistering}
            >
              Register
            </button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
