import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import moment from 'moment';

// icons
import { FaUserPlus } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaUserFriends } from 'react-icons/fa';
// import { SiSololearn } from "react-icons/si";

import adminServices from './../../services/admin.service';

// components
import AdminDashboardWrapper from '../../components/SuperAdmin/AdminDashboardWrapper';
// import DashboardTable from "../../components/dashboard/Table";
import { AdminCard } from './../../components/SuperAdmin/AdminCard';
import Loader from '../../components/Loader';

function AdminDashboard() {
  const user = useSelector((state) => state.user);
  const adminData = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatch(adminServices.getDashboard(user.token));

        if (!response.success) {
          console.log(response.error.message);
        } else {
          setLoading(false); // Set loading to false when data is fetched successfully
        }
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error || status);
      }
    })();
  }, []);

  // useEffect(() => {
  //   if (adminData && adminData.users) {
  //     setStateData(adminData.users._list);
  //   }
  // }, [adminData]);

  const allUserLength = adminData?.users?._count || 0;
  const basicUserLength = adminData?.users?.basicUsers || 0;
  const memberUserLength = adminData?.users?.members || 0;

  return (
    <AdminDashboardWrapper>
      <div className="mt-12">
        {/* <h2 className="text-lg font-medium">Last 30 days</h2> */}
        <div className="mt-2 grid md:grid-cols-3 gap-4">
          <AdminCard
            CardIcon={FaUsers}
            title="All Users"
            value={allUserLength}
            link="/dashboard"
          />
          <AdminCard
            CardIcon={FaUserFriends}
            title="Basic Users"
            value={basicUserLength}
          />
          <AdminCard
            CardIcon={FaUserPlus}
            title="Members"
            value={memberUserLength}
          />
        </div>
      </div>
    </AdminDashboardWrapper>
  );
}

export default AdminDashboard;
