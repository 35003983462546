import React from "react";
import { useNavigate } from "react-router-dom";

import RegisterCompo from "../../components/Authentication/Register";
// layouts
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

function Register() {
  const navigate = useNavigate();

  return (
    <div className="pb-32">
      <Header />
      <div className="sm:container sm:mx-auto sm:max-w-lg pt-12 sm:pt-24 min-h-[calc(100vh-142px)]">
        <RegisterCompo cb={() => navigate("/", { replace: true })} />
      </div>
      <Footer />
    </div>
  );
}

export default Register;
