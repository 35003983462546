import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// icons
import { AiOutlineDollarCircle } from "react-icons/ai";
import { HiOutlineCursorClick } from "react-icons/hi";
import { FaBalanceScale } from "react-icons/fa";
import { SiSololearn } from "react-icons/si";

// components
import DashboardWrapper from "../../components/dashboard/Wrapper";
import { BasicCard } from "../../components/dashboard/Cards";
import DashboardTable from "../../components/dashboard/Table";

const columns = [
  {
    id: "reference",
    label: "Reference",
    minWidth: 100,
    format: (value) => "#" + value,
  },
  {
    id: "platform",
    align: "center",
    label: "Platform",
    minWidth: 350,
  },
  {
    id: "email",
    align: "center",
    label: "Email",
    minWidth: 250,
  },
  // {
  //   id: "points",
  //   label: "Points",
  //   minWidth: 40,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "earned",
    align: "center",
    label: "Earned",
    minWidth: 100,
    format: (value) => "$" + value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "date",
    label: "Date",
    minWidth: 190,
    align: "center",
  },
];

function createData(
  reference,
  platform,
  email,
  earned,
  description,
  date,
  pending
) {
  return { reference, platform, email, earned, description, date, pending };
}

// const rows = [
//   createData(812623, 12213.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 0.7, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
//   createData(812623, 123.172, "Testing", 6, "March 10, 2023 6:18pm"),
// ];

function Dashboard() {
  const dashboardData = useSelector((state) => state.dashboard);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (dashboardData) {
      let mapData = dashboardData.activities.map((dt) => {
        return createData(
          dt.reference,
          dt.platform,
          dt.email,
          // dt.points,
          dt.earned,
          dt.description,
          moment(dt.createdAt).format("llll"),
          dt.pending
        );
      });

      setRows(mapData);
    }
  }, [dashboardData]);

  return (
    <DashboardWrapper>
      <div className="mt-12">
        <h2 className="text-lg font-medium">Last 30 days</h2>
        <div className="mt-2 grid md:grid-cols-3 gap-4">
          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Referrals"
            value={dashboardData?.activities.length || 0}
            link="/dashboard"
          />
          <BasicCard
            CardIcon={HiOutlineCursorClick}
            title="Visits"
            value={dashboardData?.visits.length || 0}
          />
          <BasicCard
            CardIcon={FaBalanceScale}
            title="Conversion Rate"
            value={(dashboardData?.conversionRate || 0) + "%"}
          />
        </div>
      </div>
      <div className="mt-12">
        <h2 className="text-lg font-medium">All-time</h2>
        <div className="mt-2 grid sm:grid-cols-2 md:grid-cols-4 gap-4">
          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Referrals"
            value={dashboardData?.activities.length || 0}
            link="/dashboard/referrals"
          />
          <BasicCard
            CardIcon={HiOutlineCursorClick}
            title="Visits"
            value={dashboardData?.visits.length || 0}
          />
          <BasicCard
            CardIcon={FaBalanceScale}
            title="Conversion Rate"
            value={(dashboardData?.conversionRate || 0) + "%"}
          />
          {/* <BasicCard
            CardIcon={SiSololearn}
            title="Points Earned"
            value={dashboardData?.points || 0}
            link="/dashboard/referrals"
          /> */}
          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Available Earnings"
            value={"$" + (dashboardData?.availableEarning.toFixed(2) || 0)}
            link="/dashboard/payouts"
          />
          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Total Earnings"
            value={"$" + (dashboardData?.totalEarning.toFixed(2) || 0)}
            link="/dashboard/payouts"
          />
        </div>
      </div>
      <div className="mt-12">
        <h2 className="text-lg font-medium">Recent referral activities</h2>
        <DashboardTable className="mt-6" columns={columns} rows={rows} />
      </div>
    </DashboardWrapper>
  );
}

export default Dashboard;
