import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

// icons
import { AiOutlineUserAdd } from "react-icons/ai";

// services
import teamServices from "../../services/team.service";

// components
import DashboardWrapper from "../../components/dashboard/Wrapper";
import DashboardTable from "../../components/dashboard/Table";

const columns = [
  {
    id: "sr",
    label: "Sr.",
    minWidth: 170,
    format: (value) => "#" + value,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "center",
  },
  {
    id: "payingType",
    label: "Paying Type",
    minWidth: 200,
    align: "center",
  },
  {
    id: "password",
    label: "Password",
    minWidth: 200,
    align: "center",
  },
  {
    id: "date",
    label: "Date",
    minWidth: 290,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 190,
    align: "center",
  },
];

function createData(sr, email, payingType, password, date, action) {
  return { sr, email, payingType, password, date, action };
}

// const rows = [
//   createData(1, "example@gmail.com", 23, "March 10, 2023 6:18pm"),
//   createData(2, "example@gmail.com", 23, "March 10, 2023 6:18pm"),
//   createData(3, "example@gmail.com", 23, "March 10, 2023 6:18pm"),
//   createData(4, "example@gmail.com", 23, "March 10, 2023 6:18pm"),
// ];

function Teams() {
  const user = useSelector((state) => state.user);

  const [rows, setRows] = useState([]);
  const [email, setEmail] = useState("");
  const [payingType, setPayingType] = useState("Commission");
  const [payingValue, setPayingValue] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await teamServices.getTeam(user?._id);

        if (response.success) {
          let mapData = response.data.members.map((dt, idx) => {
            return createData(
              idx + 1,
              dt.user.email,
              dt.paying.type === "Hourly"
                ? "$" + dt.paying.value + " (Hourly)"
                : dt.paying.value + "% (Commission)",
              dt.password,
              moment(dt.createdAt).format("llll"),
              {
                value: "Remove",
                handlerFunction: () => {
                  handleRemoveUser(dt.user._id);
                },
              }
            );
          });
          setRows(mapData);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleRemoveUser = async (memberId) => {
    try {
      // if (!window.confirm("Are you sure?")) {
      //   return;
      // }
      console.log(memberId);
      const response = await teamServices.removeMember(user?._id, memberId);
      if (response.success) {
        let mapData = response.data.members.map((dt, idx) => {
          toast.success("Member Removed");
          return createData(
            idx + 1,
            dt.user.email,
            dt.paying.type === "Hourly"
              ? "$" + dt.paying.value + " (Hourly)"
              : dt.paying.value + "% (Commission)",
            dt.password,
            moment(dt.createdAt).format("llll"),
            {
              value: "Remove",
              handlerFunction: () => {
                handleRemoveUser(dt.user._id);
              },
            }
          );
        });
        setRows(mapData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddUser = async () => {
    try {
      if (!email || !payingType || !payingValue) {
        toast.error("Required fields are empty!");
        return;
      }

      let body = {
        email,
        payingType,
        payingValue,
        referral: user?.referral,
      };
      const response = await teamServices.addMember(user?._id, body);
      if (response.success) {
        let mapData = response.data.members.map((dt, idx) => {
          toast.success("Member Removed");
          return createData(
            idx + 1,
            dt.user.email,
            dt.paying.type === "Hourly"
              ? "$" + dt.paying.value + " (Hourly)"
              : dt.paying.value + "% (Commission)",
            dt.password,
            moment(dt.createdAt).format("llll"),
            {
              value: "Remove",
              handlerFunction: () => {
                handleRemoveUser(dt.user._id);
              },
            }
          );
        });
        setRows(mapData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardWrapper>
      <div className="mt-12">
        <h2 className="text-lg font-medium">Add a new member</h2>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="w-full max-w-lg px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3] outline-none"
            type="email"
            placeholder="E-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <select
            name="payingType"
            id="payingType"
            className="px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3] outline-none"
            onChange={(e) => setPayingType(e.target.value)}
          >
            <option value="Hourly"> Hourly </option>
            <option value="Commission"> Commission </option>
          </select>
          <input
            className="px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3] outline-none"
            type="number"
            pattern="[0-9]"
            placeholder="Rate"
            onChange={(e) => setPayingValue(e.target.value)}
          />
          <button
            className="w-fit flex items-center px-6 py-2 text-base border-[1px] border-[#343c45] dark:border-[#cccccc] space-x-2 rounded-md"
            onClick={handleAddUser}
          >
            <AiOutlineUserAdd />
            <span>Add</span>
          </button>
        </div>
      </div>

      <div className="mt-6">
        <h2 className="text-lg font-medium">Team members</h2>
        <DashboardTable className="mt-4" columns={columns} rows={rows} />
      </div>
    </DashboardWrapper>
  );
}

export default Teams;
