import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, Avatar, Stack } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import useSocket from "../../context/Socket";

import { readNotification } from "../../utils/reducers/adminSlice";

export default function NotificationViewer() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.admin.notifications._list);
  const [notification, setNotification] = useState();
  const [open, setOpen] = React.useState(false);
  const { notificationId } = useParams();
  const isDesktop = useMediaQuery("(min-width: 750px)");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let data = notifications.find((n) => n._id === notificationId);
    setNotification(data);
    setOpen(true);
  }, [notificationId]);

  useEffect(() => {
    let data = notifications.find((n) => n._id === notificationId);

    if (data.readAt) return;

    let readAt = new Date().toISOString();

    socket.emit("read_notification", {
      _id: notificationId,
    });
    dispatch(readNotification({ ...data, readAt }));
  }, [notificationId]);

  if (!notification) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        {/* <img
          src="/images/Push-Notification-color.svg"
          alt="notification svg"
          className="w-48"
        /> */}
        <h3 className="mt-4 text-center text-base bg-brand-text">
          No notification found with this id! <br /> Select another one.
        </h3>
      </div>
    );
  }

  return (
    <>
      <div className="col-span-2 hidden rounded-lg bg-brand-card px-10 py-4 md:block">
        <div className="h-full w-full">
          <div className="w-full">
            <Stack>
              <Avatar
                alt={notification.user.fullName}
                src={notification.user?.profileImage}
                sx={{ width: "12rem", height: "12rem", objectFit: "cover" }}
                className="mx-auto"
              />
            </Stack>

            <h3 className="mt-4 text-center text-base font-medium text-brand-text">
              {notification.user?.fullName}
            </h3>
            <div
              className="mt-4 text-base text-brand-text"
              dangerouslySetInnerHTML={{ __html: notification.message }}
            ></div>
          </div>
        </div>
      </div>
      {/* Mobile view popup view */}
      {!isDesktop && (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <div className="flex justify-end space-y-8 bg-brand-card px-10 py-8">
            <button
              type="button"
              className="dark:hover:brand-primary mb-2 mr-2 rounded-lg border  border-brand-primary px-4 py-2 text-center text-sm font-medium text-brand-primary hover:bg-brand-primary hover:text-white dark:border-brand-primary dark:text-brand-primary dark:hover:text-white"
              onClick={handleClose}
            >
              <IoIosClose className="h-6 w-6" />
            </button>
          </div>
          <div className="col-span-2 rounded-lg bg-brand-card p-4">
            <div className="flex h-full w-full flex-col items-center justify-center">
              <div className="mx-2">
                <Stack>
                  <Avatar
                    alt={notification.user.fullName}
                    src={notification.user?.profileImage}
                    sx={{ width: "8rem", height: "8rem", objectFit: "cover" }}
                    className="mx-auto"
                  />
                </Stack>

                <h3 className="mt-4 text-center text-base font-medium text-neutral-800 dark:text-neutral-300">
                  {notification.user?.fullName}
                </h3>
                <div
                  className="mt-4 break-all text-base text-neutral-800 dark:text-neutral-300"
                  dangerouslySetInnerHTML={{ __html: notification.message }}
                ></div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
