// modules
import axios from "../utils/axios";

const services = {
  getTeam: (ownerId) => {
    return axios({
      url: "/api/teams/" + ownerId,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  addMember: (ownerId, body) => {
    return axios({
      url: "/api/teams/" + ownerId + "/addMember",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  removeMember: (ownerId, memberId) => {
    return axios({
      url: "/api/teams/" + ownerId + "/removeMember/" + memberId,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        return {
          success: true,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
};

export default services;
