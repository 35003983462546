import React from "react";
import { Link } from "react-router-dom";

export function AdminCard({ CardIcon, title, value, link = false }) {
  return (
    <div className="bg-brand-card shadow-lg rounded-xl overflow-hidden">
      <div className="px-6 py-4 flex bg-brand-card">
        <div className="mt-3 text-xl md:text-2xl text-brand-text">
          <CardIcon />
        </div>
        <div className="ml-3">
          <h6 className="text-xs md:text-sm text-brand-text font-medium">
            {" "}
            {title}{" "}
          </h6>
          <p className="text-2xl md:text-3xl dark:text-[#bdbdbd] font-bold">
            {value}
          </p>
        </div>
      </div>
      {/* {link && (
        <div className="px-6 py-2 bg-[#ececec] dark:bg-[#1a222a]">
          <Link
            to={link}
            className="text-[#AD1418] text-xs md:text-sm underline"
          >
            View all
          </Link>
        </div>
      )} */}
    </div>
  );
}
