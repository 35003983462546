// modules
import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: null,
  reducers: {
    setDashboardData: (state, action) => {
      const { payload } = action;
      return payload;
    },
    updateDashboardData: (state, action) => {
      const { payload } = action;
      const conversionRate =
        (payload.activities.length / payload.visits.length) * 100;
      return {
        ...state,
        conversionRate: conversionRate === NaN ? 0 : conversionRate.toFixed(2),
        ...payload,
      };
    },
  },
});

export const { setDashboardData, updateDashboardData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
