import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Input from './../BankInfo/Input';

import { toast } from 'react-toastify';
import authServices from '../../services/auth.service';

const initialValues = {
  email: '',
};

const emailValidation = Yup.object({
  email: Yup.string()
    .required('Email address is required.')
    .email('Please enter a valid email address.')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      'Please enter a valid email address.'
    ),
});

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const [state, setState] = useState(initialValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = async (values) => {
    try {
      const response = await authServices.forgotPassword(values.email);
      if (response.success) {
        toast.success(response.status);

        navigate('/', { replace: true });
      } else {
        toast.error(response.error.message);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error || status);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={emailValidation}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <Form>
          <Input
            type="email"
            name="email"
            placeholder="Enter your email"
            onBlur={form.handleBlur}
            value={state.email}
            onChange={handleChange}
            required
          />

          <button
            disabled={form.isSubmitting}
            type="submit"
            className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
          >
            Reset my Password
          </button>

          {/* <motion.button
            type="submit"
            disabled={form.isSubmitting}
            className="mx-auto h-12 text-base font-semibold rounded-md drop-shadow-lg w-full bg-brand-primary text-white place-items-center  flex items-center justify-center gap-2 mt-10"
            whileHover={{ y: -3 }}>
            Reset my Password
          </motion.button> */}
          <p className="text-center text-neutral-800 dark:text-neutral-300 mt-8">
            Go to{' '}
            <Link to="/" className="text-brand-primary hover:underline">
              Login
            </Link>
          </p>
        </Form>
      )}
    </Formik>
  );
}
