import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationItem from './NotificationItem';
import { Link, useSearchParams } from 'react-router-dom';
import HasMoreLoader from '../ui/HasMoreLoader';
import Filter from './Filter';
import { useSelector } from 'react-redux';

export default function InfiniteNotification() {
  const dNotifications = useSelector((state) => state.admin.notifications);
  const [notifications, setNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [queryParams] = useSearchParams();
  const filter = queryParams.get('filter');

  // Create a separate state for the selected filter
  const [selectedFilter, setSelectedFilter] = useState(filter || 'all');

  const filterOptions = ['All', 'Unread', 'Registration'];

  function fetchMoreData() {
    setTimeout(() => {
      //   setNotifications(notifications.concat(data(50)));
    }, 1000);
  }
  const handleFilterChange = (newFilter) => {
    // Update the selected filter when the user changes it
    setSelectedFilter(newFilter);
  };

  useEffect(() => {
    // Filter notifications based on the selected filter
    let filteredNotifications = dNotifications._list;

    if (selectedFilter) {
      const lowerSelectedFilter = selectedFilter.toLowerCase(); // Convert to lowercase

      if (lowerSelectedFilter === 'unread') {
        filteredNotifications = dNotifications._list.filter(
          (notification) => notification.readAt === null
        );
      } else if (lowerSelectedFilter !== 'all') {
        filteredNotifications = dNotifications._list.filter((notification) =>
          notification.type.toLowerCase().includes(lowerSelectedFilter)
        );
      }
    }

    console.log('Initial dNotifications:', dNotifications._list);
    console.log('selectedFilter:', selectedFilter);
    console.log('filteredNotifications:', filteredNotifications);

    // Set filtered notifications and adjust hasMore
    setNotifications(filteredNotifications);
    setHasMore(false); // Always set hasMore to false initially to prevent issues

    // Implement your data fetching logic here to load more data
    fetchMoreData();
  }, [dNotifications, selectedFilter]);

  // Implement your data fetching logic here for fetchMoreData

  return (
    <ul className="space-y-1 py-4">
      <div className="mb-4">
        <Filter
          name="Filter"
          filterBy="filter"
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
          onFilterChange={handleFilterChange}
        />
      </div>
      <InfiniteScroll
        dataLength={notifications.length}
        next={fetchMoreData} // Provide the function for loading more data
        hasMore={hasMore}
        loader={<HasMoreLoader />}
        height={500}
      >
        {notifications.map((notification) => (
          <li key={notification._id} className="mx-4">
            <Link to={notification._id}>
              <NotificationItem {...notification} />
            </Link>
          </li>
        ))}
      </InfiniteScroll>
    </ul>
  );
}
