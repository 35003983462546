import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authServices from "../services/auth.service";
import useSocket from "../context/Socket";
import { useDispatch, useSelector } from "react-redux";

// icons
import {
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineClose,
} from "react-icons/ai";
import { RxHamburgerMenu, RxCardStack } from "react-icons/rx";
import { FaHistory } from "react-icons/fa";
import { FiLink, FiLogOut } from "react-icons/fi";
import { HiOutlineChartPie } from "react-icons/hi";
import { MdOutlinePayments } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";

// components
import ThemeSwitchButtom from "../components/ThemeSwitchButtom";

function Header() {
  const socket = useSocket();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [showSidebar, setShowSidebar] = useState(false);

  const handleLogout = async () => {
    try {
      let userId = user._id;
      const response = await dispatch(authServices.logout());

      if (response.success) {
        socket.emit("disconnect_user", userId);
        toast.success("Logged out");
        navigate("/", { replace: true });
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  if (
    location.pathname === "/" ||
    location.pathname === "/register" ||
    location.pathname === "/resetpassword" ||
    location.pathname === "/forgotpassword"
  ) {
    return (
      <header className="flex justify-between items-center py-2 md:py-4 px-4 md:px-6">
        <NavLink exact to="/">
          <img
            src="/static/images/logo.png"
            className="w-10 md:w-16 object-contain"
          />
        </NavLink>

        <ThemeSwitchButtom />
      </header>
    );
  } else {
    return (
      <>
        <header className="flex justify-between lg:justify-end items-center py-2 md:py-4 px-4 md:px-6">
          <NavLink exact to="/dashboard" className="lg:hidden">
            <img
              src="/static/images/logo.png"
              className="w-10 md:w-16 object-contain"
              alt="logo"
            />
          </NavLink>

          <div className="flex items-center space-x-3">
            <ThemeSwitchButtom />
            <button
              className="lg:hidden text-brand-text text-2xl outline-none"
              onClick={() => {
                setShowSidebar((prev) => !prev);
              }}
            >
              <RxHamburgerMenu />
            </button>
          </div>
        </header>
        <aside
          className="fixed left-0 bottom-0 top-0 w-64 h-[calc(100vh - 32)] my-4 ml-4 py-6 shadow-xl bg-brand-card rounded-xl -translate-x-80 lg:translate-x-0 transition-transform duration-300 ease-in-out z-[102]"
          style={showSidebar ? { transform: "translateX(0)" } : {}}
        >
          <button
            className="lg:hidden absolute top-2 right-2 text-brand-text text-xl"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <AiOutlineClose />
          </button>
          <nav className="px-4 hidden lg:block">
            <NavLink exact to="/dashboard">
              <img
                src="/static/images/logo.png"
                className="w-10 md:w-16 object-contain"
              />
            </NavLink>
            <div className="my-6 border-b-[1px] dark:border-[#343c45]"></div>
          </nav>

          <ul className="pl-4 pt-4 lg:pt-0 text-[#161C24] dark:text-[#cfcfd3] text-base space-y-1">
            <li>
              <NavLink
                exact
                to="/dashboard"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard" ? "bg-brand-bg" : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <AiOutlineHome className="text-xl" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/dashboard/affiliate_urls"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/affiliate_urls"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <FiLink className="text-xl" />
                <span>Partner URLs</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/dashboard/statistics"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/statistics"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <HiOutlineChartPie className="text-xl" />
                <span>Statistics</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/dashboard/referrals"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/referrals"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <RxCardStack className="text-xl" />
                <span>Referrals</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/dashboard/transection-history"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/transection-history"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <FaHistory className="text-xl" />
                <span>History</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/dashboard/payouts"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/payouts"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <MdOutlinePayments className="text-xl" />
                <span>Payouts</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                exact
                to="/dashboard/teams"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/teams"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <RiTeamLine className="text-xl" />
                <span>Teams</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                exact
                to="/dashboard/settings"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/dashboard/settings"
                    ? "bg-brand-bg"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <AiOutlineSetting className="text-xl" />
                <span>Settings</span>
              </NavLink>
            </li>
            <li>
              <button
                className={`w-full flex items-center pl-6 py-3 hover:bg-brand-bg rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
                onClick={handleLogout}
              >
                <FiLogOut className="text-xl" />
                <span>Log out</span>
              </button>
            </li>
          </ul>
        </aside>
      </>
    );
  }
}

export default Header;
