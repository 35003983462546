import React from "react";
import { Dialog } from "@mui/material";
import Register from "../Authentication/Register";

function RegisterUser({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Register cb={handleClose} superAdminAction={true} />
    </Dialog>
  );
}

export default RegisterUser;
