// modules
import { createTheme } from "@mui/material/styles";

export const tableDarkTheme = createTheme({
  components: {
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "var(--bg)",
    //     },
    //   },
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "#343c45",
          color: "var(--text-color)",
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#cfcfd3",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#cfcfd3 !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#cfcfd3 !important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#cfcfd3 !important",
        },
      },
    },

    MuiSelectOutlined: {
      styleOverrides: {
        root: {
          color: "#cfcfd3 !important",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#cfcfd3 !important",
        },
      },
    },
  },
});

export const tableLightTheme = createTheme({
  components: {
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "var(--bg)",
    //     },
    //   },
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "#e5e7eb",
          color: "var(--text-color)",
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#161C24",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#161C24 !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#161C24 !important",
        },
      },
    },
    MuiSelectSelect: {
      styleOverrides: {
        root: {
          color: "#161C24 !important",
        },
      },
    },

    MuiSelectOutlined: {
      styleOverrides: {
        root: {
          color: "#161C24 !important",
        },
      },
    },
  },
});
