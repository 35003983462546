import { createSlice } from '@reduxjs/toolkit';

const bankSlice = createSlice({
  name: 'bank',
  initialState: [],
  reducers: {
    setBankInfos: (state, action) => {
      const { payload } = action;
      return payload;
    },

    setGetBankInfo: (state, action) => {
      const { payload } = action;
      return { ...state, bankInfos: payload };
    },
    addBankInfo: (state, action) => {
      return [action.payload, ...state];
    },
    // removeBankInfo: (state, action) => {
    //   return state.filter((bankInfo) => bankInfo._id !== action.payload._id);
    // },
    // removeBankInfo: (state, action) => {
    //   const { payload } = action;
    //   let tmpList = state.filter((dt) => dt._id !== payload);

    //   return { ...state, bank: tmpList };
    // },

    removeBankInfo: (state, action) => {
      const deletedBankId = action.payload._id;
      return state.filter((bankInfo) => bankInfo._id !== deletedBankId);
    },

    // removeBankInfo: (state, action) => {
    //   return [...state].filter(
    //     (bankInfo) => bankInfo._id !== action.payload._id
    //   );
    // },
    updateBankInfo: (state, action) => {
      const updatedBankInfo = action.payload;
      const index = state.findIndex(
        (bankInfo) => bankInfo._id === updatedBankInfo._id
      );
      if (index !== -1) {
        return state.map((bankInfo, i) =>
          i === index ? updatedBankInfo : bankInfo
        );
      }
      return state;
    },

    setWithdrawInfo: (state, action) => {
      const { payload } = action;
      return { ...state, bankInfos: payload };
    },
  },
});

export const {
  setBankInfos,
  addBankInfo,
  removeBankInfo,
  updateBankInfo,
  setGetBankInfo,
  setWithdrawInfo,
} = bankSlice.actions;
export default bankSlice.reducer;
