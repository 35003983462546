import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import withdrawService from "../../services/withdraw.service";

const WithdrawBankModal = ({
  user,
  dashboardData,
  bankInfos,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState(10);

  useEffect(() => {
    setAccountNumber(bankInfos[0]?.beneficiaryBank.accountNumber);
  }, [bankInfos]);

  console.log("bankId bankInfos: ", bankInfos[0]);

  const handleWithdraw = async () => {
    let data = { amount, accountNumber };
    if (amount >= 10 && amount > dashboardData.clearedEarning) {
      toast.error("Insufficient balance");
      return;
    }
    try {
      let response = await dispatch(withdrawService.withdraw(user._id, data));

      if (response.success) {
        toast.success("Withdraw request sent!");
        setAmount(10);
        onClose();
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <div className="px-4 py-8 text-brand-text bg-brand-card">
        <div className="flex flex-col space-y-3">
          <label htmlFor="email" className="text-lg">
            Account Number
          </label>
          <select
            name="accountNumber"
            id="accountNumber"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            className="px-2 py-1 rounded-md bg-brand-bg outline-none text-inherit"
          >
            {bankInfos ? (
              bankInfos.map((dt, idx) => (
                <option key={idx} value={dt.beneficiaryBank.accountNumber}>
                  {dt.beneficiaryBank.accountNumber}
                </option>
              ))
            ) : (
              <span>Please add a Bank Account first</span>
            )}
          </select>
        </div>
        <div className="mt-3 flex flex-col space-y-3">
          <label htmlFor="amount" className="text-lg">
            Amount
          </label>
          <input
            id="amount"
            name="amount"
            type="number"
            min={10}
            max={dashboardData.clearedEarning}
            className="px-2 py-1 rounded-md bg-brand-bg outline-none"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={handleWithdraw}
            className="max-w-[200px] w-full px-4 py-2 bg-brand-primary text-white rounded-md transform hover:scale-105 transition-all"
          >
            Request Withdraw
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default WithdrawBankModal;
