import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import withdrawService from '../../services/withdraw.service';

// icons
import { SiSololearn } from 'react-icons/si';

// components
import DashboardWrapper from '../../components/dashboard/Wrapper';
import DashboardTable from '../../components/dashboard/Table';
import { BasicCard } from '../../components/dashboard/Cards';

const columns = [
  {
    id: 'userFullName',
    label: 'Full Name',
    minWidth: 100,
  },
  {
    id: 'amount',
    align: 'center',
    label: 'Amount',
    minWidth: 100,
    format: (value) => '$' + value.toLocaleString('en-US'),
  },
  {
    id: 'method',
    align: 'center',
    label: 'Method',
    minWidth: 250,
  },
  {
    id: 'withdrawStatus',
    align: 'center',
    label: 'Withdraw Status',
    minWidth: 100,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 190,
    align: 'center',
  },
];

function createData(userFullName, amount, method, withdrawStatus, date) {
  return { userFullName, amount, method, withdrawStatus, date };
}

function TransectionHistory() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const withdrawl = useSelector((state) => state.withdrawal);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatch(
          withdrawService.getWithdrawInfos(user._id)
        );
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
      }
    })();
  }, []);

  useEffect(() => {
    if (withdrawl) {
      let mapData = withdrawl.withdrawalHistory.map((dt) => {
        return createData(
          dt.userFullName,
          dt.amount,
          dt.method,
          dt.withdrawStatus,
          moment(dt.createdAt).format('llll'),
          dt.date
        );
      });

      setRows(mapData);
    }
  }, [withdrawl]);

  return (
    <DashboardWrapper>
      <div className="mt-12">
        {' '}
        <h2 className="text-lg font-medium">Withdraw History</h2>
        <DashboardTable className="mt-6" columns={columns} rows={rows} />
      </div>
    </DashboardWrapper>
  );
}

export default TransectionHistory;
