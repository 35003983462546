// modules
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action) => {
      const { payload } = action;
      return { ...payload.user, token: payload.token };
    },
    updateUser: (state, action) => {
      const { payload } = action;
      return { ...state, ...payload };
    },
    removeUser: () => {
      return null;
    },
  },
});

export const { setUser, removeUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
