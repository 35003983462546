import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  return (
    <div className="absolute left-0 right-0 bottom-0 bg-brand-card py-4">
      <div className="xl:container flex flex-col md:flex-row md:justify-between items-center xl:mx-auto px-8 text-[#9393a1] text-xs md:text-sm leading-normal">
        <div className="text-center md:text-left">
          <span>© 2022 Made by</span>{" "}
          <a
            href="#"
            target="_blank"
            className="text-lightmedium-gray hover:text-brand-primary underline"
          >
            David Queen
          </a>
        </div>
        <div>
          <Link
            to="/contact"
            className="text-lightmedium-gray hover:text-brand-primary underline"
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
