// modules
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

// reducer
import userReducer from './reducers/userSlice';
import themeReducer from './reducers/themeSlice';
import dashboardReducer from './reducers/dashboardSlice';
import adminReducer from './reducers/adminSlice';
import bankReducer from './reducers/bankSlice';
import withdrawalReducer from './reducers/withdrawalSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
    dashboard: dashboardReducer,
    admin: adminReducer,
    bank: bankReducer,
    withdrawal: withdrawalReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
