import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import authServices from "../../services/auth.service";
import { setBankInfos } from "./../../utils/reducers/bankSlice";

// icons
import { BiMoneyWithdraw } from "react-icons/bi";
import { AiOutlineDollarCircle, AiOutlinePlus } from "react-icons/ai";
import { FaPaypal } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";

// components
import DashboardWrapper from "../../components/dashboard/Wrapper";
import { BasicCard } from "../../components/dashboard/Cards";
import BankInfoForm from "../../components/BankInfo/BankInfoForm";
import BankInfoCard from "../../components/dashboard/BankInfoCard";
import WithdrawBankModal from "../../components/BankInfo/WithdrawBankModal";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Success from "../../components/ui/Success";

function Payouts() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const dashboardData = useSelector((state) => state.dashboard);
  const availableEarning = useSelector(
    (state) => state.dashboard.availableEarning
  );
  const bankInfos = useSelector((state) => state.bank);
  // const [bankData, setBankData] = useState([]);

  // console.log('bankInfos: ', bankInfos);

  const [open, setOpen] = useState(false);
  const [openWithdrawal, setOpenWithdrawal] = useState(false);
  const [openBankWithdrawal, setOpenBankWithdrawal] = useState(false);
  const [openBankForm, setOpenBankForm] = useState(false);

  // useEffect(() => {
  //   if (bankInfos) {
  //     setBankData(bankInfos);
  //   }
  // }, [bankInfos, dashboardData]);

  // useEffect(() => {
  //   setBankData(bankInfos);
  // }, [bankInfos]);

  useEffect(() => {
    // This function will be called whenever bankInfos is updated
    console.log("bankInfos updated:", bankInfos);
  }, [bankInfos, availableEarning]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatch(authServices.getBankInfos(user._id));
        console.log("response data: ", response);
      } catch (err) {
        console.log("payout error:", err);
        let error = err.response.data.error;
        let status = err.response.data.status;
      }
    })();
  }, [dispatch, user._id]);

  return (
    <DashboardWrapper>
      <div className="mt-12">
        <h2 className="text-lg font-medium">Overview</h2>
        <div className="mt-2 grid md:grid-cols-3 gap-4">
          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Total Earnings"
            value={"$" + (dashboardData?.totalEarning.toFixed(2) || 0)}
          />

          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Available Earnings"
            value={"$" + (availableEarning.toFixed(2) || 0)}
          />

          <BasicCard
            CardIcon={AiOutlineDollarCircle}
            title="Cleared Earnings"
            value={"$" + (dashboardData?.clearedEarning.toFixed(2) || 0)}
          />
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => setOpenWithdrawal(true)}
            className="mt-8 flex items-center space-x-2 px-4 py-2 bg-brand-primary text-white rounded-md transform hover:scale-105 transition-all shadow-md"
          >
            <BiMoneyWithdraw />
            <span>Withdraw through Paypal</span>
          </button>
          <button
            onClick={() => setOpenBankWithdrawal(true)}
            className="mt-8 flex items-center space-x-2 px-4 py-2 bg-brand-primary text-white rounded-md transform hover:scale-105 transition-all shadow-md"
          >
            <BiMoneyWithdraw />
            <span>Withdraw through Bank</span>
          </button>
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-lg font-medium">Withdrawal Methods</h2>

        <div className="grid md:grid-cols-6 gap-4">
          {user.paymentMethods.map((dt, idx) => (
            <WithdrawalCard key={idx} email={dt} />
          ))}

          <button
            onClick={handleOpen}
            className="mt-2 md:col-span-2 flex flex-col items-center p-6 bg-brand-card rounded-md space-y-4"
          >
            <AiOutlinePlus className="text-5xl" />
            <span> Add new one</span>
          </button>
        </div>
      </div>
      <AddPaymentMethod user={user} open={open} onClose={handleClose} />

      <div className="mt-6">
        <h2 className="text-lg font-medium">Add Bank Methods</h2>

        <div className="grid md:grid-cols-6 gap-4">
          {bankInfos.map((bankInfo) => (
            <BankInfoCard key={bankInfo._id} bankInfo={bankInfo} />
          ))}

          {/* <BankInfoCard bankInfo={bankInfos} /> */}

          <button
            onClick={() => setOpenBankForm(true)}
            className="mt-2 md:col-span-2 flex flex-col items-center p-6 bg-brand-card rounded-md space-y-4"
          >
            <AiOutlinePlus className="text-5xl items-center" />
            <span> Add new one</span>
          </button>
        </div>
      </div>
      <BankInfoForm
        open={openBankForm}
        onClose={() => setOpenBankForm(false)}
      />
      <WithdrawBankModal
        user={user}
        bankInfos={bankInfos}
        dashboardData={dashboardData}
        open={openBankWithdrawal}
        onClose={() => setOpenBankWithdrawal(false)}
      />
      <WithdrawalModal
        user={user}
        open={openWithdrawal}
        dashboardData={dashboardData}
        onClose={() => setOpenWithdrawal(false)}
      />
    </DashboardWrapper>
  );
}

function WithdrawalCard({ user, email }) {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    let data = { email };

    try {
      let response = await dispatch(
        authServices.removePaymentMethod(user._id, data)
      );

      if (response.success) {
        toast.success(response.status);
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };
  return (
    <div className="withdrawal_card mt-2 md:col-span-3 p-6 bg-brand-card rounded-md overflow-hidden">
      <div className="flex flex-col items-center space-y-4">
        <FaPaypal className="text-5xl" />
        <span> {email}</span>
      </div>
      <div className="overlay absolute top-1/2 left-0 right-0 bottom-0 p-6 bg-brand-bg shadow-lg flex justify-center items-center space-x-4">
        {/* <button className="px-6 py-1 text-sm bg-[#5cb85c] border-[1px] rounded-sm">
          Edit
        </button> */}
        <button
          onClick={handleDelete}
          className="px-6 py-1 text-sm bg-brand-primary text-white border-[1px] rounded-sm"
        >
          Delete
        </button>
      </div>
    </div>
  );
}

const AddPaymentMethod = ({ user, dashboardData, open, onClose }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleSave = async () => {
    let data = { email };
    if (user.paymentMethods.includes(email)) {
      toast.error("This email is already saved");
      return;
    }
    try {
      let response = await dispatch(
        authServices.addPaymentMethod(user._id, data)
      );

      if (response.success) {
        toast.success(response.status);
        setEmail("");
        onClose();
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <div className="px-4 py-8 text-brand-text bg-brand-card">
        <div className="flex flex-col space-y-3">
          <label htmlFor="email" className="text-lg">
            {" "}
            Paypal email address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="px-2 py-1 rounded-md bg-brand-bg outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={handleSave}
            className="max-w-[120px] w-full px-4 py-2 bg-brand-primary text-white rounded-md transform hover:scale-105 transition-all"
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const WithdrawalModal = ({ user, dashboardData, open, onClose }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(10);

  useEffect(() => {
    setEmail(user.paymentMethods[0]);
  }, []);

  const handleWithdraw = async () => {
    let data = { amount, email };
    if (amount >= 10 && amount > dashboardData.clearedEarning) {
      toast.error("Insufficient balance");
      return;
    }
    try {
      let response = await dispatch(authServices.withdraw(user._id, data));

      if (response.success) {
        toast.success("Withdraw request sent!");
        setAmount(10);
        onClose();
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <div className="px-4 py-8 text-brand-text bg-brand-card">
        <div className="flex flex-col space-y-3">
          <label htmlFor="email" className="text-lg">
            Email
          </label>
          <select
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="px-2 py-1 rounded-md bg-brand-bg outline-none text-inherit"
          >
            {user.paymentMethods.map((dt, idx) => (
              <option key={idx} value={dt}>
                {dt}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3 flex flex-col space-y-3">
          <label htmlFor="amount" className="text-lg">
            Amount
          </label>
          <input
            id="amount"
            name="amount"
            type="number"
            min={10}
            max={dashboardData.clearedEarning}
            className="px-2 py-1 rounded-md bg-brand-bg outline-none"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={handleWithdraw}
            className="max-w-[200px] w-full px-4 py-2 bg-brand-primary text-white rounded-md transform hover:scale-105 transition-all"
          >
            Request Withdraw
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default Payouts;
