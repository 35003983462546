import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// import Header from "../../components/Dashboard/Header";
import UsersTable from "../../components/SuperAdmin/UserTable";
import AdminDashboardWrapper from "../../components/SuperAdmin/AdminDashboardWrapper";

export default function Users() {
  const data = useSelector((state) => state.admin.users);
  const [stateData, setStateData] = useState([]);

  // console.log('user page data', data);

  useEffect(() => {
    setStateData(data._list);
  }, [data]);

  return (
    <>
      {/* <Header title={"User details"} /> */}
      <AdminDashboardWrapper>
        <div className="grid mt-8 grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <UsersTable rows={stateData} setStateData={setStateData} />
        </div>
      </AdminDashboardWrapper>
    </>
  );
}
