import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import adminService from '../../services/admin.service';

// icons
import { SiSololearn } from 'react-icons/si';

// components
import DashboardWrapper from '../../components/dashboard/Wrapper';
import DashboardTable from '../../components/dashboard/Table';
import { BasicCard } from '../../components/dashboard/Cards';
import AdminDashboardWrapper from '../../components/SuperAdmin/AdminDashboardWrapper';
import { useNavigate } from 'react-router-dom';
import AllTransections from '../../components/SuperAdmin/AllTransections';

const columns = [
  {
    id: 'userFullName',
    label: 'Full Name',
    minWidth: 200,
  },
  {
    id: 'amount',
    align: 'center',
    label: 'Amount',
    minWidth: 100,
    format: (value) => '$' + value.toLocaleString('en-US'),
  },
  {
    id: 'accountNumber',
    align: 'center',
    label: 'Account Number',
    minWidth: 200,
  },
  {
    id: 'method',
    align: 'center',
    label: 'Method',
    minWidth: 100,
  },
  {
    id: 'withdrawStatus',
    align: 'center',
    label: 'Withdraw Status',
    minWidth: 100,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 190,
    align: 'center',
  },
];

function createData(
  userFullName,
  amount,
  accountNumber,
  method,
  withdrawStatus,
  date
) {
  return { userFullName, amount, accountNumber, method, withdrawStatus, date };
}

function TransectionHistory() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const withdrawl = useSelector((state) => state.withdrawal.allTransection);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    // Fetch data based on current page and items per page
    fetchData(currentPage, itemsPerPage);
  }, [dispatch, user, currentPage, itemsPerPage]);

  const fetchData = async (page, perPage) => {
    try {
      const response = await dispatch(
        adminService.getAllTransection(user.token, page, perPage)
      );

      // Handle response and update the component state
      // if (response.success && response.data && response.data._list) {
      //   setRows(response.data._list);
      //   setLoading(false);
      // }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      // Handle error
    }
  };

  const handlePageChange = (newPage) => {
    // Update the current page
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newPerPage) => {
    // Update the items per page and reset to page 1
    setItemsPerPage(newPerPage);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await dispatch(
  //         adminService.getAllTransection(user.token)
  //       );
  //     } catch (err) {
  //       let error = err.response.data.error;
  //       let status = err.response.data.status;
  //     }
  //   })();
  // }, [dispatch, user]);

  // console.log('user page data', withdrawl);

  useEffect(() => {
    if (withdrawl && withdrawl._list) {
      setRows(withdrawl._list);
    }
  }, [withdrawl]);

  return (
    <AdminDashboardWrapper>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {/* <AllTransections rows={rows} setStateData={setRows} /> */}
        <AllTransections
          rows={rows}
          setStateData={setRows}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
    </AdminDashboardWrapper>
  );
}

export default TransectionHistory;
