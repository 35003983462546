import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// icons
import { SiSololearn } from "react-icons/si";

// components
import DashboardWrapper from "../../components/dashboard/Wrapper";
import DashboardTable from "../../components/dashboard/Table";
import { BasicCard } from "../../components/dashboard/Cards";

const columns = [
  {
    id: "reference",
    label: "Reference",
    minWidth: 100,
    format: (value) => "#" + value,
  },
  {
    id: "platform",
    align: "center",
    label: "Platform",
    minWidth: 350,
  },
  {
    id: "email",
    align: "center",
    label: "Email",
    minWidth: 250,
  },
  // {
  //   id: "points",
  //   label: "Points",
  //   minWidth: 40,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "earned",
    align: "center",
    label: "Earned",
    minWidth: 100,
    format: (value) => "$" + value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "date",
    label: "Date",
    minWidth: 190,
    align: "center",
  },
];

function createData(
  reference,
  platform,
  email,

  earned,
  description,
  date,
  pending
) {
  return { reference, platform, email, earned, description, date, pending };
}

function Referrals() {
  const dashboardData = useSelector((state) => state.dashboard);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (dashboardData) {
      let mapData = dashboardData.activities.map((dt) => {
        return createData(
          dt.reference,
          dt.platform,
          dt.email,
          // dt.points,
          dt.earned,
          dt.description,
          moment(dt.createdAt).format("llll"),
          dt.pending
        );
      });

      setRows(mapData);
    }
  }, [dashboardData]);

  return (
    <DashboardWrapper>
      {/* <div className="mt-12">
        <h2 className="text-lg font-medium">Overview</h2>
        <div className="mt-2 grid md:grid-cols-3 gap-4">
          <BasicCard
            CardIcon={SiSololearn}
            title="Unpaid Points"
            value={dashboardData?.points || 0}
          />
          <BasicCard
            CardIcon={SiSololearn}
            title="Total Points"
            value={dashboardData?.points || 0}
          />
        </div>
      </div> */}
      <div className="mt-12">
        {" "}
        <h2 className="text-lg font-medium">Recent referral activities</h2>
        <DashboardTable className="mt-6" columns={columns} rows={rows} />
      </div>
    </DashboardWrapper>
  );
}

export default Referrals;
