import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import ForgotPasswordForm from '../../components/Authentication/ForgetPassForm';

function Reset() {
  return (
    <>
      <Header />

      <div className="sm:container sm:mx-auto sm:max-w-lg sm:pt-24 min-h-[calc(100vh-142px)]">
        {' '}
        {/*min-height:calc(100vh - 135px) in upper div*/}
        <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
          <div className="header font-bold text-[#161C24] dark:text-[#cfcfd3] text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
            Reset your password
          </div>
          {/*  */}

          <div className="p-5 pb-20">
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Reset;
