// modules
import axios from "../utils/axios";
import {
  setAdminData,
  removeUser,
  customEntryOrReferral,
} from "../utils/reducers/adminSlice";
import { setAllTransectons } from "../utils/reducers/withdrawalSlice";
import { updateUser } from "../utils/reducers/userSlice";

const services = {
  getDashboard: (token) => (dispatch) => {
    return axios({
      url: "/api/admin/dashboard",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(setAdminData(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  approvalUpdate: (userId, token) => (dispatch) => {
    return axios({
      url: "/api/admin/dashboard/" + userId + "/update/account",
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  deleteUser: (userId, token) => (dispatch) => {
    return axios({
      url: "/api/admin/delete/user/" + userId,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(removeUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  getAllTransection: (token, page, perPage) => (dispatch) => {
    return axios({
      url: "/api/admin/dashboard/transections",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      params: {
        page: page,
        perPage: perPage,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(setAllTransectons(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },
  customEntryOrReferral: (token, data) => (dispatch) => {
    return axios({
      url: "/api/admin/custom/referral",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data,
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(customEntryOrReferral(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },
};

export default services;
