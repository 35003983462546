import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivetRoutes() {
  const user = useSelector((state) => state.user);
  return !user ? (
    <Navigate to="/" />
  ) : user.accountType === 'Admin' ? (
    <Navigate to="/admin/dashboard" />
  ) : (
    <Outlet />
  );
}
