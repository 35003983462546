import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

// icons
import { BsFacebook, BsGoogle } from "react-icons/bs";

// services
import authServices from "../../services/auth.service";

// layouts
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMortgageAccount, setIsMortgageAccount] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    session: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    let toastId = toast.loading("Logging in...");
    e.preventDefault();
    try {
      setIsLogin(true);
      let response;

      if (isMortgageAccount) {
        response = await dispatch(authServices.loginMortgage(formValues));
      } else {
        response = await dispatch(authServices.login(formValues));
      }

      if (response.success) {
        toast.update(toastId, {
          render: response.status,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        navigate("/dashboard", { replace: true });
      } else {
        setIsLogin(false);

        toast.update(toastId, {
          render: response.status,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setIsLogin(false);
      console.log("error: ", err);
      let status = err.response.data.status;
      toast.update(toastId, {
        render: status,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="pb-32">
      <Header />
      <div className="sm:container sm:mx-auto sm:max-w-lg pt-12 sm:pt-24 min-h-[calc(100vh-142px)]">
        {" "}
        {/*min-height:calc(100vh - 135px) in upper div*/}
        <div className="w-full sm:rounded-md shadow-lg break-words bg-brand-card">
          <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
            LogIn
          </div>
          {/*  */}
          {/* login with social */}
          <div className="loginWLink p-5">
            <h5 className="text-[#161C24] dark:text-white text-center">
              Log in with one of the following:
            </h5>
            <div className="allLinks flex justify-center mt-3">
              {!isMortgageAccount && (
                <>
                  <a href="/api/users/auth/facebook">
                    <BsFacebook className="h-9 w-9 cursor-pointer text-brand-primary mr-3" />
                  </a>
                  <a href="/api/users/auth/google">
                    <BsGoogle className="h-9 w-9 cursor-pointer text-brand-primary mr-3" />
                  </a>
                </>
              )}

              <button
                onClick={() => {
                  setIsMortgageAccount((prev) => !prev);
                }}
              >
                <img src="/static/images/logo.png" className="h-9 w-9" />
              </button>
            </div>
          </div>

          {/* orr */}
          <div
            className="text-center  relative before:content-[''] before:block max-[415px]:before:w-[130px]  before:w-[200px] before:h-[2px] before:absolute before:left-0 before:top-1/2  before:bg-[#424250] before:ml-7 
     after:content-[''] after:block  max-[415px]:after:w-[130px] after:w-[200px] after:h-[2px] after:absolute after:right-0 after:top-1/2  after:bg-[#424250] after:mr-7"
          >
            <span className="text-white bg-[#33333D]">OR</span>
          </div>

          <div className="form pt-2 pb-8">
            <form
              className="w-full px-6 sm:px-8 pt-6 pb-4 space-y-4 sm:space-y-6"
              onSubmit={handleLogin}
            >
              <div className="email">
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  placeholder="E-Mail Address"
                  required
                  className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>

              <div className="pass">
                <input
                  type="password"
                  name="password"
                  value={formValues.password}
                  placeholder="Password"
                  required
                  className="bg-brand-bg form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>

              {/* forget */}
              <div className="flex items-center">
                <label
                  htmlFor="remember"
                  className="inline-flex items-center text-sm"
                >
                  <input
                    type="checkbox"
                    name="session"
                    id="remember"
                    className="form-checkbox h-4 w-4 bg-[#fff] text-brand-primary border border-[#d2d6dc] "
                    onChange={() =>
                      setFormValues((prev) => ({
                        ...prev,
                        session: !prev.session,
                      }))
                    }
                  />{" "}
                  <span className="ml-2 text-[#161C24] dark:text-white">
                    Remember Me
                  </span>
                </label>{" "}
                <Link
                  href=""
                  className="text-sm text-brand-primary whitespace-no-wrap no-underline hover:underline ml-auto"
                  to="/forgotpassword"
                >
                  Forgot Your Password?
                </Link>
              </div>

              {/* buttton others */}
              <div className="flex flex-wrap">
                <button
                  type="submit"
                  className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-brand-primary sm:py-4"
                  disabled={isLogin}
                >
                  Login
                </button>{" "}
              </div>
            </form>
            <div className="text-brand-text flex items-center justify-center space-x-2 text-sm whitespace-no-wrap">
              <span>Don't have a account?</span>
              <Link
                className="text-sm text-brand-primary no-underline hover:underline"
                to="/register"
              >
                Register now!
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LogIn;
