// modules
import axios from "../utils/axios";
import { setDashboardData } from "../utils/reducers/dashboardSlice";

const services = {
  fetchData: (userId, token) => (dispatch) => {
    return axios({
      url: "/api/refer/" + userId,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(setDashboardData(resData.data));
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
};

export default services;
