import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// services
import dashboardServices from '../../services/dashboard.service';

// layouts
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

// components
import Loader from '../Loader';
import { toast } from 'react-toastify';

function DashboardWrapper({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const dashboardData = useSelector((state) => state.dashboard);
  const bankInfos = useSelector((state) => state.bank);

  useEffect(() => {
    if (!user) navigate('/', { replace: true });
    else if (!dashboardData || dashboardData === null) {
      (async () => {
        try {
          const response = await dispatch(
            dashboardServices.fetchData(user._id, user.token)
          );

          if (!response.success) {
            navigate('/', { replace: true });
          }

          if (response.status === 401) {
            // Show a toast message when the account is not approved
            toast.error(response.data.message);
            navigate('/', { replace: true });
          }
        } catch (err) {
          navigate('/', { replace: true });
        }
      })();
    }
  }, [user]);

  if (dashboardData === null || !dashboardData || !bankInfos) {
    return <Loader />;
  } else if (!user) return;

  return (
    <div className="relative min-h-screen p-4 pb-36 lg:ml-72">
      <Header />

      <main className="text-[#161C24] dark:text-[#cfcfd3]">
        <h1 className="text-3xl font-medium">
          Welcome, {user.firstName + ' ' + user.lastName}
        </h1>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default DashboardWrapper;
