import React, { useState } from "react";
// import { RxDashboard } from 'react-icons/rx';
// import { IoMdNotificationsOutline } from 'react-icons/io';
// import { IoSettingsOutline } from 'react-icons/io5';
// import { MdOutlineManageAccounts } from 'react-icons/md';

// icons
import { FiLogOut } from "react-icons/fi";
import { RxHamburgerMenu, RxCardStack } from "react-icons/rx";
import { FaUsers } from "react-icons/fa";
import ThemeSwitchButtom from "../ThemeSwitchButtom";
import useSocket from "../../context/Socket";
import {
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineClose,
} from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
// import { motion } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";

import authServices from "../../services/auth.service";
import { toast } from "react-toastify";
import Loader from "../Loader";

export default function AdminAside() {
  const socket = useSocket();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const notifications = useSelector((state) => state.admin.notifications);
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const withdrawl = useSelector((state) => state.withdrawal);
  // const notifications = useSelector((state) => state.admin.notifications);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleLogout = async () => {
    try {
      let userId = user._id;
      const response = await dispatch(authServices.logout());

      if (response.success) {
        socket.emit("disconnect_user", userId);
        toast.success("Logged out");
        navigate("/", { replace: true });
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  if (location.pathname === "/" || location.pathname === "/register") {
    return (
      <header className="flex justify-between items-center py-2 md:py-4 px-4 md:px-6">
        <NavLink exact to="/">
          <img
            src="/static/images/logo.png"
            className="w-10 md:w-16 object-contain"
            alt="logo"
          />
        </NavLink>

        <ThemeSwitchButtom />
      </header>
    );
  } else {
    return !admin ||
      admin === null ||
      !admin.notifications ||
      admin.notifications.unread_count === null ||
      !withdrawl ? (
      <Loader />
    ) : (
      <>
        <header className="flex justify-between lg:justify-end items-center py-2 md:py-4 px-4 md:px-6">
          <NavLink exact to="/dashboard" className="lg:hidden">
            <img
              src="/static/images/logo.png"
              className="w-10 md:w-16 object-contain"
              alt="logo"
            />
          </NavLink>

          <div className="flex items-center space-x-3">
            <ThemeSwitchButtom />
            <button
              className="lg:hidden text-brand-text text-2xl outline-none"
              onClick={() => {
                setShowSidebar((prev) => !prev);
              }}
            >
              <RxHamburgerMenu />
            </button>
          </div>
        </header>
        <aside
          className="fixed left-0 bottom-0 top-0 w-64 h-[calc(100vh - 32)] my-4 ml-4 py-6 shadow-xl bg-brand-card rounded-xl -translate-x-80 lg:translate-x-0 transition-transform duration-300 ease-in-out z-[102]"
          style={showSidebar ? { transform: "translateX(0)" } : {}}
        >
          <button
            className="lg:hidden absolute top-2 right-2 text-brand-text text-xl"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <AiOutlineClose />
          </button>
          <nav className="px-4 hidden lg:block">
            <NavLink exact to="/dashboard">
              <img
                src="/static/images/logo.png"
                className="w-10 md:w-16 object-contain"
                alt="logo"
              />
            </NavLink>
            <div className="my-6 border-b-[1px] dark:border-[#343c45]"></div>
          </nav>
          <ul className="pl-4 pt-4 lg:pt-0 text-brand-text text-base space-y-1">
            <li>
              <NavLink
                exact
                to="/admin/dashboard"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg ${
                  location.pathname === "/admin/dashboard" ? "bg-brand-bg" : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <AiOutlineHome className="text-xl" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/admin/dashboard/users"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg dark:hover: ${
                  location.pathname === "/admin/dashboard/users"
                    ? "bg-brand-bg dark:"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <FaUsers className="text-xl" />
                <span>Users</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/admin/dashboard/notification"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg dark:hover: ${
                  location.pathname === "/admin/dashboard/notification"
                    ? "bg-brand-bg dark:"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <IoMdNotificationsOutline className="text-xl" />
                <span>Notification</span>
                {admin.notifications?.unread_count > 0 && (
                  <span className="ml-auto grid h-6 w-6 place-items-center rounded-full bg-[#e92222] text-xs font-thin text-white">
                    {admin.notifications.unread_count < 99
                      ? admin.notifications.unread_count
                      : "99+"}
                  </span>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/admin/dashboard/referrals"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg dark:hover: ${
                  location.pathname === "/admin/dashboard/referrals"
                    ? "bg-brand-bg dark:"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <RxCardStack className="text-xl" />
                <span>Referrals</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/admin/dashboard/transections"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg dark:hover: ${
                  location.pathname === "/admin/dashboard/transections"
                    ? "bg-brand-bg dark:"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <AiOutlineSetting className="text-xl" />
                <span>Transactions</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/admin/dashboard/settings"
                className={`flex items-center pl-6 py-3 hover:bg-brand-bg dark:hover: ${
                  location.pathname === "/admin/dashboard/settings"
                    ? "bg-brand-bg dark:"
                    : ""
                } rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
              >
                <AiOutlineSetting className="text-xl" />
                <span>Settings</span>
              </NavLink>
            </li>
            <li>
              <button
                className={`w-full flex items-center pl-6 py-3 hover:bg-brand-bg dark:hover: rounded-tl-xl rounded-bl-xl space-x-3 transition-all duration-300 ease-in-out`}
                onClick={handleLogout}
              >
                <FiLogOut className="text-xl" />
                <span>Log out</span>
              </button>
            </li>
          </ul>
        </aside>
      </>
    );
  }
}
