import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  Avatar,
  Button,
  Paper,
  Table,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
// import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { DateRangePicker } from "react-date-range";
import { isWithinInterval, startOfDay, endOfDay } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

// Action
import withdrawService from "../../services/withdraw.service";

import { setWithdrawalStatus } from "../../utils/reducers/withdrawalSlice";

// components
import Success from "../ui/Success";
import Error from "../ui/Error";
import LoadingSpinner from "../ui/LoadingSpinner";
import SearchFilter from "./SearchFilter";

// themes
import { tableDarkTheme, tableLightTheme } from "../../utils/themes.mui";
import TransectionHistory from "./../../pages/dashboard/TransectionHistory";
import TransFltr from "./TransFltr";

function AllTransections({
  className,
  rows,
  homepage,
  currentPage,
  itemsPerPage,
  onPageChange,
}) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const [searchParams] = useSearchParams();
  const allHistory = searchParams.get("withdrawal");
  const search = searchParams.get("search");

  const [transections, setTransections] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState("Completed");
  const [selectedHistory, setSelectedHistory] = useState([]);
  const [filteredUserTransactions, setFilteredUserTransactions] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dateRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const dateRangeClass = document.querySelectorAll(
    ".rdrCalendarWrapper, .rdrDefinedRangesWrapper, .rdrStaticRanges, .rdrDateDisplayWrapper, .rdrStaticRange, .rdrNextPrevButton, .rdrNextButton, .rdrInputRangeInput, .rdrDateDisplayItem"
  );
  dateRangeClass.forEach((element) => {
    element.style.backgroundColor = "var(--bg)";
  });

  const dateRangePickerStyles = {
    backgroundColor: "var(--bg)",
    // Add other styles as needed
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (allHistory && allHistory.toLowerCase() === "all") {
      setTransections(rows);
      setFilteredUserTransactions(rows);
      return;
    }
    if (allHistory) {
      const filteredUser = rows.filter((td) =>
        td.withdrawStatus.toLowerCase().includes(allHistory.toLowerCase())
      );
      setTransections(filteredUser);
      setFilteredUserTransactions(filteredUser);
    } else if (search) {
      const filteredUser = rows.filter((user) =>
        Object.values(user).find((value) => {
          if (typeof value === "string") {
            return value.replace(/\s/g, "").toLowerCase().includes(search);
          }
          return false;
        })
      );

      setTransections(filteredUser);
      setFilteredUserTransactions(filteredUser);
    } else {
      setTransections(rows);
      setFilteredUserTransactions(rows);
    }
  }, [allHistory, search, rows, filteredUserTransactions, startDate, endDate]);

  const filteredTransactions = transections.filter((transaction) => {
    if (startDate && endDate) {
      const transactionDate = new Date(transaction.date);

      // Use startOfDay and endOfDay to get the beginning and end of the day
      const startOfStartDate = startOfDay(startDate);
      const endOfEndDate = endOfDay(endDate);

      // Use isWithinInterval to check if the transaction date is within the selected range
      return isWithinInterval(transactionDate, {
        start: startOfStartDate,
        end: endOfEndDate,
      });
    }
    return true;
  });
  // new code
  // React.useEffect(() => {
  //   // Filter transactions based on the selected date range
  //   const filteredByDate = rows.filter((transaction) => {
  //     if (startDate && endDate) {
  //       const transactionDate = new Date(transaction.date);

  //       // Use startOfDay and endOfDay to get the beginning and end of the day
  //       const startOfStartDate = startOfDay(startDate);
  //       const endOfEndDate = endOfDay(endDate);

  //       // Use isWithinInterval to check if the transaction date is within the selected range
  //       return isWithinInterval(transactionDate, {
  //         start: startOfStartDate,
  //         end: endOfEndDate,
  //       });
  //     }
  //     return true; // Include all transactions if no date range is selected
  //   });

  //   // Filter transactions based on the search query
  //   const filteredBySearch = filteredByDate.filter((transaction) =>
  //     Object.values(transaction).some((value) => {
  //       if (typeof value === 'string') {
  //         return value.replace(/\s/g, '').toLowerCase().includes(search);
  //       }
  //       return false;
  //     })
  //   );

  //   setTransections(filteredBySearch);
  // }, [startDate, endDate, search, rows]);
  // new code

  const totalProcessingAmount = filteredTransactions
    .filter((transaction) => transaction.withdrawStatus === "Processing")
    .reduce((total, transaction) => total + transaction.amount, 0);

  // Calculate the total completed amount
  const totalCompletedAmount = filteredTransactions
    .filter((transaction) => transaction.withdrawStatus === "Completed")
    .reduce((total, transaction) => total + transaction.amount, 0);

  // Calculate the total amount
  const totalAmount = filteredTransactions.reduce(
    (total, transaction) => total + transaction.amount,
    0
  );

  const handleDateChange = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const handleDateReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleSelectIndividual = (id) => {
    if (selectedHistory.findIndex((dt) => dt._id === id) > -1) {
      let tmpt = selectedHistory.filter((dt) => dt._id !== id);
      setSelectedHistory(tmpt);
    } else {
      let tmpt = rows.find((dt) => dt._id === id);
      setSelectedHistory((prev) => [...prev, tmpt]);
    }
  };

  const handleSelectAll = () => {
    if (rows.length === selectedHistory.length) {
      setSelectedHistory([]);
    } else {
      setSelectedHistory(rows);
    }
  };

  // console.log(selectedHistory);

  const handleStatusUpdateMultiple = async () => {
    if (selectedHistory.length > 0) {
      await Promise.all(
        selectedHistory.map(async (dt) => {
          await handleStatusUpdate(dt._id, selectedStatus);
        })
      );

      setSelectedHistory([]);
    } else {
      toast.error("No user selected");
    }
  };

  const handleStatusUpdate = async (withdrawId, status) => {
    try {
      const response = await dispatch(
        withdrawService.updateWithdrawalStatus(withdrawId, status)
      );

      if (response.success) {
        toast.success(response.status);
        const updatedTransections = transections.map((transaction) => {
          if (transaction._id === withdrawId) {
            return {
              ...transaction,
              withdrawStatus: status,
            };
          }
          return transaction;
        });

        // Update the state with the new transections array
        setTransections(updatedTransections);
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <ThemeProvider theme={theme === "dark" ? tableDarkTheme : tableLightTheme}>
      <div className="col-span-3 overflow-hidden rounded-md bg-brand-card">
        <div className="mb-2 flex w-full flex-wrap items-center justify-between border-b  border-gray-300 px-4 py-4 dark:border-gray-700">
          <h4 className="text-bse py-2 text-xl font-medium text-brand-text">
            All Transection details
          </h4>
          {/* <TransFltr /> */}
        </div>

        <div className="mb-2 flex w-full flex-wrap items-center justify-between border-b border-gray-300 px-4 py-4 dark:border-gray-700">
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            {/* DateRangePicker */}
            <div className="bg-brand-bg">
              <DateRangePicker
                className="bg-brand-bg"
                onChange={handleDateChange}
                ranges={[dateRange]}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                style={dateRangePickerStyles}
              />
            </div>
            {/* Total Amounts */}{" "}
            <div className="flex flex-wrap items-center gap-4 bg-brand-bg shadow-lg rounded-xl overflow-hidden">
              <div className="px-6 py-4 col-span-3 overflow-hidden rounded-md bg-brand-bg">
                {/* Total Processing Amount */}
                <div className="mb-4">
                  <span className="text-brand-text  font-semibold">
                    Total Processing Amount:
                  </span>
                  <span className="ml-2 text-green-600 font-semibold">
                    ${totalProcessingAmount.toFixed(2)}
                  </span>
                </div>

                {/* Total Completed Amount */}
                <div className="mb-4">
                  <span className="text-brand-text font-semibold">
                    Total Completed Amount:
                  </span>
                  <span className="ml-2 text-green-600 font-semibold">
                    ${totalCompletedAmount.toFixed(2)}
                  </span>
                </div>

                {/* Total Amount */}
                <div className="mb-4">
                  <span className="text-brand-text font-semibold">
                    Total Amount:
                  </span>
                  <span className="ml-2 text-blue-600 font-semibold">
                    ${totalAmount.toFixed(2)}
                  </span>
                </div>

                <button
                  onClick={handleDateReset}
                  className="rounded-md bg-brand-primary px-8 py-2 text-white"
                >
                  Reset Dates
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-2 flex w-full flex-wrap items-center justify-end border-b border-gray-300 px-4 py-4 dark:border-gray-700">
          <div className="flex flex-wrap items-center gap-4">
            <SearchFilter />
            {selectedHistory.length > 0 && (
              <>
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select value={selectedStatus} onChange={handleStatusChange}>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>{" "}
                <button
                  className="rounded-md bg-[#161C24] text-[#cfcfd3] px-8 py-2 dark:text-[#161C24] dark:bg-[#cfcfd3]"
                  onClick={handleStatusUpdateMultiple}
                >
                  Update
                </button>
              </>
            )}
          </div>
        </div>

        <TableContainer className="px-4">
          <Table stickyHeader aria-label="sticky table" className="">
            <thead className="bg-brand-card text-brand-text">
              <TableRow>
                <TableCell
                  className="md:sticky"
                  align="center"
                  style={{ minWidth: 80, fontSize: 13 }}
                >
                  <Checkbox
                    checked={
                      rows.length === selectedHistory.length &&
                      selectedHistory.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {!homepage && (
                  <TableCell
                    style={{
                      minWidth: 150,
                      fontWeight: 600,
                      fontSize: 13,
                    }}
                  >
                    Profile picture
                  </TableCell>
                )}

                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: 200, fontWeight: 600, fontSize: 13 }}
                    className={`bg-brand-card ${
                      column.id === "userFullName"
                        ? "left-0 z-10 md:sticky"
                        : column.id === "accountNumber"
                        ? "left-[200px] z-10 md:sticky"
                        : ""
                    }`}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </thead>
            <TableBody>
              {filteredTransactions
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, fontSize: 13 }}
                    >
                      <Checkbox
                        checked={
                          selectedHistory.findIndex(
                            (dt) => dt._id === row._id
                          ) > -1
                        }
                        onChange={() => handleSelectIndividual(row._id)}
                      />
                    </TableCell>
                    {!homepage && (
                      <TableCell align="center">
                        <Avatar
                          src={row.profileImage}
                          alt={row.userFullName}
                          sx={{
                            height: "2.5rem",
                            width: "2.5rem",
                            objectFit: "cover",
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "subscription") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: 13 }}
                          >
                            {value.status}
                          </TableCell>
                        );
                      } else if (column.id === "date") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: 13 }}
                          >
                            {moment(value.date).format("llll")}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ fontSize: 13 }}
                          className={`bg-brand-card dark:text-[#cfcfd3] ${
                            column.id === "userFullName"
                              ? "left-0 z-10 md:sticky"
                              : column.id === "accountNumber"
                              ? "left-[200px] z-10 md:sticky"
                              : ""
                          }`}
                        >
                          {typeof value === "boolean"
                            ? value
                              ? "Yes"
                              : "No"
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredTransactions.length}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={(event, newPage) => onPageChange(newPage + 1)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </ThemeProvider>
  );
}

export default AllTransections;

const columns = [
  {
    id: "userFullName",
    label: "Full Name",
    minWidth: 200,
  },
  {
    id: "userEmail",
    label: "Email",
    minWith: 200,
  },
  {
    id: "amount",
    align: "center",
    label: "Amount",
    minWidth: 100,
    format: (value) => "$" + value.toLocaleString("en-US"),
  },
  {
    id: "accountNumber",
    align: "center",
    label: "Account Number",
    minWidth: 200,
  },
  {
    id: "userPaypalMail",
    align: "center",
    label: "Paypal Email",
    minWidth: 200,
  },
  {
    id: "method",
    align: "center",
    label: "Method",
    minWidth: 100,
  },
  {
    id: "withdrawStatus",
    align: "center",
    label: "Withdraw Status",
    minWidth: 100,
  },
  {
    id: "date",
    label: "Date",
    minWidth: 190,
    align: "center",
  },
];
