// modules
import { createSlice } from "@reduxjs/toolkit";

const themeslice = createSlice({
  name: "theme",
  initialState: "dark",
  reducers: {
    setTheme: (state, action) => {
      const { payload } = action;

      return payload;
    },
  },
});

export const { setTheme } = themeslice.actions;
export default themeslice.reducer;
