import React from 'react';
import Filter from './Filter';
import SearchFilter from './SearchFilter';

export default function UserFilter() {
  const users = ['All', 'Basic', 'Members'];
  return (
    <div className="flex gap-2">
      <SearchFilter />
      {/* <Filter name="Users" filterBy="user" filterOptions={users} /> */}
    </div>
  );
}
