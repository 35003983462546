// modules
import axios from '../utils/axios';
import { setWithdrawInfo } from '../utils/reducers/bankSlice';
import {
  setDashboardData,
  updateDashboardData,
} from '../utils/reducers/dashboardSlice';
import {
  setWithdrawalData,
  setWithdrawalHistory,
  setWithdrawalStatus,
  setUserWithdrawalTotals,
} from '../utils/reducers/withdrawalSlice';

const services = {
  getWithdrawInfos: (userId) => (dispatch) => {
    return axios({
      url: '/api/bankinfo/withdrawal-history/' + userId,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(setWithdrawalHistory(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  withdraw: (userId, data) => (dispatch) => {
    return axios({
      url: '/api/bankinfo/' + userId + '/withdraw/',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
      data: data,
    }).then((response) => {
      const resData = response.data;
      if (resData.success) {
        dispatch(updateDashboardData(resData.data));
        dispatch(setWithdrawalData(resData.data));
        // dispatch(setWithdrawInfo(resData.data));
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  updateWithdrawalStatus: (withdrawalId, newStatus) => (dispatch) => {
    return axios({
      url: `/api/bankinfo/withdrawal/${withdrawalId}/status/`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
      data: {
        withdrawStatus: newStatus,
      },
    }).then((response) => {
      const resData = response.data;
      if (resData.success) {
        dispatch(setWithdrawalStatus(resData.data));
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  getTotalUserWithdrawal: (userId) => (dispatch) => {
    return axios({
      url: `/api/bankinfo/${userId}/total-amount/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        const resData = response.data;
        if (resData.success) {
          dispatch(setUserWithdrawalTotals(resData.data));
        }
        return resData;
      })
      .catch((error) => {
        console.log(error);
        return {
          success: false,
          status: error.response.status,
          error: error.response.data.error,
        };
      });
  },
};

export default services;
