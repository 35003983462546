import { useField, ErrorMessage } from "formik";
import React from "react";

export default function Input({ placeholder, htmlForm, label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className="flex flex-col space-y-2 pb-4">
      <label htmlFor={htmlForm} className="text-lg">
        {" "}
        {label}
      </label>
      <input
        type={field.type} // Use directly from field, as it's not nested
        name={field.name}
        placeholder={placeholder}
        {...field}
        {...props}
        className="px-4 py-2 mb-4 text-[#161C24] dark:text-white rounded-md bg-brand-bg outline-none w-full"
      />
      {meta.touched && meta.error && (
        <div className="text-sm  text-brand-primary">
          <ErrorMessage name={field.name} />
        </div>
      )}
    </div>
  );
}
