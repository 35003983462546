import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import ResetPasswordForm from '../../components/Authentication/ResetPassForm';
import { useSearchParams } from 'react-router-dom';

import authServices from '../../services/auth.service';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let token = searchParams.get('t');
        const response = await authServices.verifyResetToken(token);

        if (!response.success) {
          setError(true);
        }
      } catch (err) {
        setError(true);
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl">
        <div className="flex justify-center items-center min-h-screen">
          <div className="w-full md:max-w-lg sm:rounded-md shadow-lg break-words bg-brand-card">
            {/* <div className="header font-bold text-brand-heading text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-neutral-800 dark:border-neutral-300 text-[#161C24] dark:text-[#cfcfd3]">
              Reset your password
            </div> */}

            <div className="header font-bold text-[#161C24] dark:text-[#cfcfd3] text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
              Reset your password
            </div>

            <div className="p-5 pb-20">
              {error ? (
                <div className="py-2 px-6 text-lg text-[#161C24] dark:text-[#cfcfd3] font-bold bg-brand-primary text-center mt-10">
                  Error: invalid_request - invalid token or token expired!
                </div>
              ) : (
                <ResetPasswordForm token={searchParams.get('t')} />
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
